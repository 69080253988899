<template>
    <div class="container">
        <div>
            <el-card class="box-card" shadow="never">
                <template #header>
                    <div class="card-header">
                        <span>查询</span>
                    </div>
                </template>
                <div class="card-content">
                    <el-form :inline="true" label-width="100px" label-position="right">
                        <el-form-item label="结算时间：">
                            <el-date-picker type="date" v-model="serchDate.time_start" placeholder="请输入创建时间"
                                value-format="YYYY-MM-DD" style="margin-right: 10px;" />
                            <el-date-picker type="date" v-model="serchDate.time_end" placeholder="请输入截至时间"
                                value-format="YYYY-MM-DD"/>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="handlSearch()">查询</el-button>  
                        </el-form-item>
                    </el-form>
                </div>
            </el-card>
        </div>
    </div>

    <div class="detail">
        <el-card shadow="never" class="card-border" v-loading="loading">
            <template #header>
                <div class="card-header">
                    <span>订单明细</span>
                    <span @click="handleExport">导出</span>
                </div>
            </template>
            <div>
                <el-table :data="tableData" stripe border style="width: 100%" size="small">
                    <el-table-column prop="trans_day" label="交易时间" width="90">
                    </el-table-column>
                    <el-table-column label="结算总计" width="80">
                        <template v-slot="scope">
                            {{ (parseFloat(scope.row.total_amount) - parseFloat(scope.row.refund_amount) -
                                parseFloat(scope.row.fee_amount)).toFixed(2)
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column label="手续费总计">
                        <template v-slot="scope">
                            {{
                                parseFloat(scope.row.fee_amount).toFixed(2)
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column label="微信实收">
                        <template v-slot="scope">
                            {{ ((parseFloat(scope.row.wxpay_pay_amount) -
                                parseFloat(scope.row.wxpay_refund_amount)).toFixed(2))>0 ?
                                ((parseFloat(scope.row.wxpay_pay_amount) -
                                parseFloat(scope.row.wxpay_refund_amount)).toFixed(2)):"0.00"
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="wxpay_refund_amount" label="微信退款">
                    </el-table-column>
                    <el-table-column label="微信手续费">
                        <template v-slot="scope">
                            {{ parseFloat(scope.row.wxpay_fee_amount).toFixed(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="微信结算">
                        <template v-slot="scope">
                            {{ ((parseFloat(scope.row.wxpay_pay_amount - parseFloat(scope.row.wxpay_refund_amount) -
                                parseFloat(scope.row.wxpay_fee_amount))).toFixed(2)) > 0 ?
                                ((parseFloat(scope.row.wxpay_pay_amount - parseFloat(scope.row.wxpay_refund_amount) -
                                parseFloat(scope.row.wxpay_fee_amount))).toFixed(2)) :"0.00"
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column label="支付宝实收">
                        <template v-slot="scope">
                            {{ ((parseFloat(scope.row.alipay_pay_amount) -
                                parseFloat(scope.row.alipay_refund_amount)).toFixed(2)) >0 ?
                                ((parseFloat(scope.row.alipay_pay_amount) -
                                parseFloat(scope.row.alipay_refund_amount)).toFixed(2)) : "0.00"
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="alipay_refund_amount" label="支付宝退款">
                    </el-table-column>
                    <el-table-column label="支付宝手续费">
                        <template v-slot="scope">
                            {{ parseFloat(scope.row.alipay_fee_amount).toFixed(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="支付宝结算">
                        <template v-slot="scope">
                            {{ ((parseFloat(scope.row.alipay_pay_amount) - parseFloat(scope.row.alipay_refund_amount) -
                                parseFloat(scope.row.alipay_fee_amount)).toFixed(2)) > 0 ?
                                ((parseFloat(scope.row.alipay_pay_amount) - parseFloat(scope.row.alipay_refund_amount) -
                                parseFloat(scope.row.alipay_fee_amount)).toFixed(2)) : "0.00"}}
                        </template>
                    </el-table-column>
                    <el-table-column label="云闪付实收">
                        <template v-slot="scope">
                            {{ ((parseFloat(scope.row.unionpay_pay_amount) -
                                parseFloat(scope.row.unionpay_refund_amount)).toFixed(2)) >0 ?
                                ((parseFloat(scope.row.unionpay_pay_amount) -
                                parseFloat(scope.row.unionpay_refund_amount)).toFixed(2)) : "0.00"
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="unionpay_refund_amount" label="云闪付退款">
                    </el-table-column>
                    <el-table-column label="云闪付手续费">
                        <template v-slot="scope">
                            {{ parseFloat(scope.row.unionpay_fee_amount).toFixed(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="云闪付结算">
                        <template v-slot="scope">
                            {{ ((parseFloat(scope.row.unionpay_pay_amount) - parseFloat(scope.row.unionpay_refund_amount) -
                                parseFloat(scope.row.unionpay_fee_amount)).toFixed(2)) > 0 ?
                                ((parseFloat(scope.row.unionpay_pay_amount) - parseFloat(scope.row.unionpay_refund_amount) -
                                parseFloat(scope.row.unionpay_fee_amount)).toFixed(2)) :"0.00"
                            }}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination">
                <el-pagination :page-size="pagination.perPage" layout="prev, pager, next" :total="pagination.total"
                    :current-page="pagination.currentPage" @current-change="handleSizeChange">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script setup>
import { onMounted, getCurrentInstance, ref } from "vue";
import { GetToday } from "../../tools/dateTools"


const { proxy } = getCurrentInstance()
const tableData = ref([])
const loading = ref(true)

const pagination = ref({
    total: 0,
    perPage: 15,
})

const getSettlementList = async (data) => {
    loading.value = true
    await proxy.$api.settlement.getSettlementList(data).then(res => {
        if (res.status == 200 && res.success) {
            tableData.value = res.data.data
            pagination.value.total = res.data.total
        }
        loading.value = false
    })
}

//查询
const handlSearch = () => {
    serchDate.value.page = 1;
    pagination.value.currentPage = 1
    getSettlementList(serchDate.value)
}

//导出
const handleExport = () => {
    proxy.$api.settlement.export(serchDate.value, "结算记录")
}

//初始页
const serchDate = ref({
    time_start: GetToday(),
    time_end: GetToday(),
    page: 1,
    pageSize: 15
})

//获取下一页
const handleSizeChange = (val) => {
    pagination.value.currentPage = val
    serchDate.value.page = val
    getSettlementList(serchDate.value)
}


onMounted(() => { getSettlementList(serchDate.value) })
</script>


<style lang="less" scoped>
.container {
    width: 100%;
    box-sizing: border-box;

    .box-card {
        border-top: 5px solid #e7e7e7;
    }

    .card-header {
        span {
            font-size: 15px;
            font-weight: bold;
        }
    }
}

.content {
    margin-top: 20px;

    .card-border {
        border-top: 5px solid #e7e7e7;

        .card-header {
            span {
                font-size: 15px;
                font-weight: bold;
            }
        }

    }

    .card-content {
        box-sizing: border-box;

        div:nth-child(1) {
            font-size: 20px;
            color: #504f4f;
            padding-left: 6px;
            font-weight: bold;
        }

        div:nth-child(2) {
            font-size: 14px;
            color: #504f4f;
            padding-top: 6px;
            padding-left: 6px;
            font-weight: bold;
        }
    }
}

.detail {
    margin-top: 20px;

    .card-border {
        border-top: 5px solid #e7e7e7;

        .card-header {
            display: flex;
            justify-content: space-between;

            span {
                font-size: 15px;
                font-weight: bold;
            }

            span:nth-child(2) {
                color: #67C23A;
                cursor: pointer;
            }

            span:nth-child(2):hover {
                color: gray;
                text-decoration: underline;
                /* 鼠标悬停时显示下划线 */
            }
        }

        .pagination {
            display: flex;
            justify-content: flex-end;
        }
    }

}</style>